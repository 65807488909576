<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            支付与发票
        </div>
        <div class="vip-content-inner">
            <div class="rich-text">
                <div class="inline-block">支付方式</div>
                <br><br>
                <p>为了方便您购买商品，您可以自由选择以下三种支付方式：</p>
                <br>
                <h3>第一：支付宝支付</h3>
                <br>
                <p>1、选择支付宝支付，提交订单，等待订单审核</p>
                <br>
                <img src="@/assets/img/rp7.jpg" alt="">
                <br>
                <p>2、审核通过后，您会收到邮件和站内消息提醒，进入“我的订单”，点击“立即支付”</p>
                <br>
                <img src="@/assets/img/rp8.jpg" alt="">
                <br>
                <p>3、进入支付页面，打开手机“支付宝”APP，扫描页面的二维码进行支付。</p>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "registerAndLogin"
}
</script>

<style scoped>

</style>
